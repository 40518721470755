//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginCard from "@/components/common/login-card";
import RegisterCard from "@/components/common/register-card";
import { mapActions, mapState } from "vuex";
import { adminCodes } from "~/enums/adminSettings";
import SelectStoreDialog from "@/components/common/select-store-dialog";
import PinLoginCard from "@/components/common/pin-login-card";

export default {
  components: {
    PinLoginCard,
    RegisterCard,
    LoginCard,
    SelectStoreDialog,
  },
  data() {
    return {
      visibleCard: "login",
      showSelectStoreDialog: false,
      logoUrl: '',
      loginText: '',
      loginContactEmailAddress: '',
      loginContactPhoneNumber: '',
    }
  },
  head() {
    return {
      title: this.$t("login"),
    };
  },

  layout (context) {
    return 'non-logged-in'
  },

  computed: {
    ...mapState("common/stores", ["stores"]),
    IsLoggedIn() {
      return this.$auth.loggedIn;
    }
  },
  async mounted() {
    if (this.IsLoggedIn) {
    }
    this.getLogoForLogin()
    this.getTextForLogin()
  },
  methods: {
    ...mapActions("common/stores", ["fetchStores"]),
    ...mapActions("currentStore", ["setCurrentStore"]),
    async loggedIn() {
      // check user's company locations and show them on dialog
      await this.fetchStores();
      if (this.stores.length === 1){
        this.onStoreSelected(this.stores[0])
      }
      else if (this.stores.length > 1) {
        this.showSelectStoreDialog = true;
      }
    },
    onStoreSelected(store) {
      this.setCurrentStore(store);
      this.$router.push("/");
    },
    async getLogoForLogin() {
      const logo = await this.$axios.$get('contents/webposlogocontent')
      this.logoUrl = logo.details.logo_img.fieldValue.fullFileUrl;
    },
    async getTextForLogin() {
      const text = await this.$axios.$get('contents/webposlogininfotextcontent')
      this.loginText = text.details.info_text.fieldValue.value;
      this.loginContactEmailAddress = text.details.email_contact.fieldValue.value;
      this.loginContactPhoneNumber = text.details.phone_contact.fieldValue.value;
    }
  },
};
